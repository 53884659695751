a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
}
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  font-size: 17px;
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
}

.loading-overlay {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #021432;
}
.dom-404 {
  background-image: url(https://xunfutech.oss-cn-beijing.aliyuncs.com/website/images/home/fail-404.png);
  background-repeat: no-repeat;
  background-position: 0% 30%;
  background-size: 100% auto;
}

ul,
li {
  list-style: none;
  margin: 0;
  margin: 0;
  padding: 0;
}
h1,
h2 {
  color: rgb(44 48 68);
}
p {
  margin: 18px 0;
  text-align: justify;
}
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}

.anchorBL {
  display: none;
}

.dom-404 {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .emoji {
    font-size: 80px;
  }
  .title {
    font-size: 40px;
  }
  .small {
    margin-bottom: 30vh;
  }
}
